import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/posts',
    name: 'Posts',
    component: () => import(/* webpackChunkName: "postlist" */ '@/views/PostList.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projectlist" */ '@/views/ProjectList.vue')
  },
  {
    path: '/links',
    name: 'Links',
    component: () => import(/* webpackChunkName: "links" */ '@/views/Links.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/alteredInternet',
    name: 'AlteredInternet',
    component: () => import(/* webpackChunkName: "alteredinternet" */ '@/views/projects/AlteredInternet.vue')
  },
  {
    path: '/projects/:id',
    name: 'restless',
    component: () => import(/* webpackChunkName: "restless" */ '@/views/projects/Restless.vue')
  },
  {
    path: '/projects/tusk',
    name: 'tusk',
    beforeEnter: () => {
      window.location.href = 'https://www.tuskband.ch'
    }
  },
  {
    path: '/projects/:id',
    name: 'music',
    component: () => import(/* webpackChunkName: "restless" */ '@/components/NotFound.vue')
  },
  {
    path:'/',
    name:'Welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '@/views/Welcome.vue')
  },
  {
    path:'/posts/:id',
    name:'PostDetails',
    component: () => import(/* webpackChunkName: "PostDetails" */ '@/views/PostDetails.vue')
  },
  {
    path:"*",
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "PostDetails" */ '@/components/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VideoBackground from 'vue-responsive-video-background-player'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false
Vue.component('video-background', VideoBackground);
Vue.use(VueViewer)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

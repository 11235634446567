
<template>
<div>
  <b-navbar toggleable="sm" type="light" fixed="top" variant="faded" :class="{ 'hide_navbar': showNavbar }">
    <div class="ml-auto openbtn">
      <b-button variant="faded" class="" @click="openNavigation">
        <b-icon class="h1" icon="list" aria-label=""></b-icon>
      </b-button>
    </div>
  </b-navbar>
  <div :class="{ 'navbar_open': showNavbar, 'collapsed': !showNavbar }">
    <div class="ml-auto closebtn">
      <b-button variant="faded" class="ml-auto closebtn" @click="closeNavigation">
        <b-icon class="h1" icon="x" aria-label="" variant="light"></b-icon>
      </b-button>
    </div>
    <ul class="navbar-content">
      <router-link class="nav-item nav-link" @click.native="closeNavigation" to="/">Home</router-link>
      <router-link class="nav-item nav-link" @click.native="closeNavigation" to="/posts">Posts</router-link>
      <router-link class="nav-item nav-link" @click.native="closeNavigation" to="/projects">Projects</router-link>
      <router-link class="nav-item nav-link" @click.native="closeNavigation" to="/about">About</router-link>
    </ul>
  </div>
      <router-view class="page-view" :key="$route.path" />
</div>
</template>

<script>
  export default {
    data() {
      return {
        showNavbar: false
      }
    },
    methods: {
      openNavigation() {
        this.showNavbar = true;
      },
      closeNavigation() {
        this.showNavbar = false;
      }
    } 
  }
</script>

<style scoped>
.navbar_open {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: hidden;
  background-color: #000000;
  transition: 0.5s;
}

.hide_navbar{
  display: none;
}

.collapsed {
  width: 100%;
  height: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  font-size: 0px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
}

.navbar-content {
  position: relative;
  width: 100%;
  text-align: left;
  padding-left: 5vw;
  font-size: 5vw;
  font-weight: bold;
}

.navbar-content a{
  color: white;
}

.navbar-content a:hover{
  color: #fdfd00;
}

.openbtn{
  transition: 0.5;
}

.altered-logo_nav{
  width: 25vw;
}

.nav-img{
  margin-left: auto;
  width: 25vw;
}

@media screen and (min-width: 800px) {
  .closebtn{
    width: 5vw;  
  }

  .navbar-content {
    top: 10%;
    font-size: 5vw;
  }

  .altered-logo{
    width: 10vw;
  }
}

@media screen and (min-width: 700px) and (max-width:799px) {
  .closebtn{
    width: 10vw;  
  }
  .navbar-content {
    top: 20%;
    font-size: 7vw;
  }

  .altered-logo{
    width: 15vw;
  }
}

@media screen and (max-width: 699px) {
  .closebtn{
    width: 15vw;  
  }

  .navbar-content {
    top: 15%;
    font-size: 12vw;
  }

  .altered-logo{
    width: 20vw;
  }
}

</style>
<template>
  <div id="app">
    <Header />
  </div>
</template>

<script>
import Header from './components/Header'

 
export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>
* { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
  height: 100%;
}

body{
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.page-view{
  margin-left: auto;
  margin-right: auto;
}

.page-view p{
  font-family: 'SwiftNeueLTW01', Georgia, serif;
  text-align: left;
}

.page-title{
  font-weight: bold;
  padding-top: 2.5em;
  text-align: left;
}
</style>
